import eventBus from "./utils/eventBus";

export default {
  template: `
      <section class="large-max-width meet-the-team">
          <div class="max-width-pad uk-padding uk-margin-xlarge-bottom">
              <div class="row uk-margin-top">
                  <div class="col col--1-of-3">
                      <div v-on:click="changeSelectedTeam(8697)" class="about-buttons uk-button uk-button-primary purple-button">LETTINGS</div>
                  </div>
                  <div class="col col--1-of-3">
                      <div v-on:click="changeSelectedTeam(8698)" class="about-buttons uk-button uk-button-primary red-button">MANAGEMENT</div>
                  </div>
                  <div class="col col--1-of-3">
                      <div v-on:click="changeSelectedTeam(8699)" class="about-buttons uk-button uk-button-primary orange-button">SALES</div>
                  </div>
                  
                  <div class="uk-margin-large-top uk-margin-large-bottom uk-text-center">
                      <h2 class="grey-text large-text">Meet our dedicated team</h2>
                  </div>
  
  
                  <div class="uk-text-center" >
  
                      <div class="col col--1-of-3 uk-margin-large-bottom uk-text-left" v-for="individual in teamList.data">
                          <div v-for="asset in individual.image">
                              <img v-bind:src="asset.url" class="team-photo" />
                          </div>
                          <div class="uk-margin-small-left">
                              <h2 class="purple-text uk-margin-top"> @{individual.fullName} </h2>
                      
                              <p class="uk-margin-small-top"> @{individual.position} </p>
                              
                          
                              <a v-bind:href="[\'tel:\' + individual.telephone.value]" class="purple-hyper">
                                  @{individual.telephone.customText}
                              </a>
                          
                              <p class="uk-margin-small-right uk-margin-small-left uk-display-inline">/ </p>
                                  
                              <a v-bind:href="[\'tel:\' + individual.mobile.value]" class="uk-margin-small-right uk-display-inline uk-margin-top purple-hyper">
                                  @{individual.mobile.customText}
                              </a>
                          
                          
                              <a href="individual.email.value" class="uk-display-block uk-margin-top uk-margin-bottom purple-hyper">
                                  @{individual.email.customText}
                              </a>      
                          </div>  
                      </div>
                  </div> 
                  <div class="col col--3-of-3 uk-text-center" v-on:click="changeSelectedTeam('')">
                      <div class="uk-button uk-button-default">Show All</div>
                  </div>    
              </div>
          </div>
      </section>
      `,
  methods: {
    getIndividuals: function (p) {
      var _this = this;

      if (this.teamSelected == undefined) {
        var teamSelectedParam = "";
      } else {
        var teamSelectedParam = this.teamSelected;
      }

      console.log(
        window.siteUrl + "team.json?" + "teamSelected=" + teamSelectedParam
      );
      this.getJSON(
        window.siteUrl + "team.json?" + "teamSelected=" + teamSelectedParam,
        function (err, data) {
          if (err !== null) {
          } else {
            console.log(data);

            if (p == undefined) {
              _this.teamList = data;
            } else {
              _this.teamList.data = _this.teamList.data.concat(data.data);
              _this.teamList.meta = data.meta;
            }
            //_this.propertyList = _.shuffle(_this.propertyList);
            _this.loadingProps = false;
            _this.paginationLoading = false;
          }
        }
      );
    },
    changeSelectedTeam: function (newSelectedValue) {
      this.teamSelected = newSelectedValue;
      this.getIndividuals();

      return;
    },
    serialize: function (obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    },
    getJSON: function (url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "json";
      xhr.onload = function () {
        var status = xhr.status;
        if (status === 200) {
          callback(null, xhr.response);
        } else {
          callback(status, xhr.response);
        }
      };
      xhr.send();
    },
  },
  mounted: function () {
    console.log("mounting...");
    this.getIndividuals();
    eventBus.on("page:before", () => {
      this.$destroy();
    });
  },
  data: function () {
    return {
      teamList: {
        data: [],
        meta: {
          pagination: {
            total: 0,
          },
        },
      },
      teamSelectedParam: "",
    };
  },
};
