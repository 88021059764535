import Flickity from "flickity";

const template = `
<div data-action='card-slider' class='absolute overflow-hidden z-10 left-0 card-slide w-full h-full top-0 object-cover'>
  <a :href="url" class="absolute inset-0 z-10"></a>
  <img class="w-full h-full object-cover object-center slide" v-for="(image) in images" key="image.url" :src="image.placeholder" :data-flickity-lazyload="image.url" />
  </div>
`;

export default {
  name: "card-slider",
  props: ["images", "url"],
  data() {
    return {
      flick: undefined,
    };
  },
  methods: {},

  mounted() {
    this.flick = new Flickity(this.$el, {
      lazyLoad: true,
      cellSelector: ".slide",
    });
  },
  template,
};
