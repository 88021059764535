import eventBus from "./utils/eventBus";
import { TweenLite } from "gsap/all";

const template = `
  <section class='pf'>

    <div class='space-x-5 mb-5 text-[#9F9F9F]'>
      <button @click="selected='Sales'" :class="selected == 'Sales' ? ' text-purple ' : ''">Sales</button>
      <button @click="selected='Lettings'" :class="selected == 'Lettings' ? ' text-purple ' : ''">Lettings</button>
    </div>

    <h3 class='text-purple mb-10 mt-0 '>Property Search</h3>
    
    <div class='flex flex-col md:flex-row items-center pf__wrap'>

      <multiselect
        :options="parishes.map(p => p.label)"
        :close-on-select="true"
        :searchable="false"
        placeholder="Parish"
        :multiple="true"
        v-model="searchParams.selectedParish"
        :taggable="true"
      ></multiselect>
      
      <div class='flex justify-between items-center w-full border-border border-b beds'>
        <button @click.prevent="searchParams.beds=searchParams.beds > 1 ? searchParams.beds-1 : searchParams.beds" class='flex'>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </button>
        <span class='mx-4 whitespace-nowrap text-sm'>
        Min Beds @{ searchParams.beds }
        </span>
        <button @click.prevent="searchParams.beds++" class='flex'>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </button>
      </div>

      <multiselect
        :options="prices[selected].map(p => p.label)"
        :close-on-select="true"
        :searchable="false"
        placeholder="Price"
        v-model="searchParams.selectedPrice"
      ></multiselect>

      <button @click.prevent="search()" class='py-9 px-20 appearance-none border border-purple rounded-md text-purple transition-all hover:bg-purple hover:text-white '>
        Search
      </button>

    </div>
  </section>
`;

const salesPrices = [
  {
    label: "Any",
  },
  {
    min: 50000,
    max: 200000,
    label: "£50K - 200K",
  },
  {
    min: 200000,
    max: 400000,
    label: "£200K - £400K",
  },
  {
    min: 400000,
    max: 600000,
    label: "£400K - £600K",
  },
  {
    min: 600000,
    max: 999999999,
    label: "£600K - £800K+",
  },
];

export default {
  name: "prop-filter",
  props: ["selectedProp"],
  components: {
    Multiselect: window.VueMultiselect.default,
  },
  data() {
    return {
      selected: "All",
      searchParams: {
        beds: 0,
        selectedParish: undefined,
        selectedPrice: undefined,
      },

      parishes: [
        {
          value: "",
          label: "Any",
        },
        {
          value: 2,
          label: "St Helier",
        },
        {
          value: 3,
          label: "Grouville",
        },
        {
          value: 4,
          label: "St Brelade",
        },
        {
          value: 5,
          label: "St Clement",
        },
        {
          value: 6,
          label: "St John",
        },
        {
          value: 7,
          label: "St Lawrence",
        },
        {
          value: 8,
          label: "St Martin",
        },
        {
          value: 9,
          label: "St Mary",
        },
        {
          value: 10,
          label: "St Ouen",
        },
        {
          value: 11,
          label: "St Peter",
        },
        {
          value: 12,
          label: "St Saviour",
        },
        {
          value: 13,
          label: "Trinity",
        },
      ],

      prices: {
        All: salesPrices,
        Sales: salesPrices,
        Lettings: [
          {
            label: "Any",
          },
          {
            min: 0,
            max: 1000,
            label: "£0 - £1K",
          },
          {
            min: 1000,
            max: 1500,
            label: "£1K - £1.5K",
          },
          {
            min: 1500,
            max: 2000,
            label: "£1.5K - £2K",
          },
          {
            min: 2000,
            max: 99999999,
            label: "£2K - £2.5K+",
          },
        ],
      },
    };
  },
  watch: {
    selected() {
      if (
        this.searchParams.selectedPrice === undefined &&
        this.searchParams.selectedParish === undefined
      ) {
        this.getProps(this.searchParams);
      } else {
        this.searchParams.selectedPrice = undefined;
        this.searchParams.selectedParish = undefined;
      }
      console.log(this.selected);
    },
    searchParams: {
      handler(newVal) {
        this.getProps(newVal);
      },
      deep: true,
    },
  },
  methods: {
    getProps({ beds, selectedParish = [], selectedPrice }) {
      const price = this.prices[this.selected].find(
        (p) => p.label == selectedPrice
      );

      const parishIds = this.parishes
        .filter((p) => selectedParish.includes(p.label))
        .map((p) => p.value);

      let queryObj = {
        parish: parishIds,
        bedrooms: this.searchParams.beds,
        minPrice: price?.min,
        maxPrice: price?.max,
      };

      if (this.selected.length) {
        const propertyType = this.selected === "Sales" ? 14 : 15;
        queryObj.propertyType = propertyType;
      }
      
      eventBus.emit("get-props", queryObj);
    },
    search() {
      const listing = document.querySelector("#listing");
      if (listing) {
        const start = window.scrollY;
        const end = listing.getBoundingClientRect().top + window.scrollY;
        let anim = {
          y: start,
        };
        TweenLite.to(anim, 0.5, {
          y: end,
          onUpdate() {
            window.scroll(0, anim.y);
          },
        });
      }
    },
  },
  mounted() {
    eventBus.on("page:before", () => {
      this.$destroy();
    });
    if (this.selectedProp.length) {
      this.selected =
        this.selectedProp[0].toUpperCase() + this.selectedProp.substring(1);
    }
  },
  template,
};
