export default function Form(el) {
  // <input type="hidden" name="action" value="contact-form/send">
  el.setAttribute("method", "POST");
  const actionEl = document.createElement("input");
  actionEl.setAttribute("type", "hidden");
  actionEl.name = "action";
  actionEl.value = "contact-form/send";
  el.append(actionEl);
  console.log(el);
}
