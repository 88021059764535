import loadScript from "./utils/loadscript";
import eventBus from "./utils/eventBus";

export default {
  props: ["single", "lat", "long", "zoom"],
  data: function () {
    return {
      markersLoaded: false,
      sale: false,
      markers: [],
    };
  },
  template: `
          <div class="map-cont">
              <div v-if="single == undefined" class="map-toggle" @click="sale=!sale"> 
                  <div class="left" v-bind:class="{ 'map-sel' : !sale }">To Let</div> 
                  <div class="right" v-bind:class="{ 'map-sel' : sale }">For Sale</div> 
              </div>
              <div class="map"></div>
              <div v-if="!markersLoaded" class="map-load">
                  <span uk-spinner="ratio: 3"></span>
              </div>
          </div>
          `,
  watch: {
    sale: {
      handler: function (sale) {
        for (var i = 0; i < this.markers.length; i++) {
          var marker = this.markers[i];
          if (marker != undefined) {
            if (sale) {
              if (marker.propertyType == "For Sale") {
                marker.setVisible(true);
              } else {
                marker.setVisible(false);
              }
            } else {
              if (marker.propertyType == "For Let") {
                marker.setVisible(true);
              } else {
                marker.setVisible(false);
              }
            }
          }
        }
      },
    },
  },
  methods: {
    initMap: function () {
      console.log(this.lat, this.long);
      var map,
        markersArray = [];

      var zoom = 12;
      if (window.innerWidth < 700) {
        zoom = 11;
      }

      if (this.zoom != undefined) {
        zoom = parseInt(this.zoom);
      }

      if (this.lat != undefined && this.long != undefined) {
        var lat = this.lat;
        var long = this.long;
      } else {
        var lat = 49.21389709439154;
        var long = -2.1312342622070446;
      }
      var mapOptions = {
        center: new google.maps.LatLng(lat, long),
        zoom: zoom,
        gestureHandling: "auto",
        fullscreenControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,

        mapTypeControl: false,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        },
        scaleControl: true,
        scrollwheel: false,
        streetViewControl: true,
        draggable: true,
        clickableIcons: true,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        streetViewControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.TOP_LEFT,
        },

        mapTypeId: google.maps.MapTypeId.ROADMAP,

        styles: [
          {
            featureType: "administrative",
            elementType: "all",
            stylers: [
              { visibility: "on" },
              { saturation: -100 },
              { lightness: 20 },
            ],
          },
          {
            featureType: "landscape.man_made",
            elementType: "all",
            stylers: [
              { visibility: "simplified" },
              { saturation: -60 },
              { lightness: 10 },
            ],
          },
          {
            featureType: "landscape.natural",
            elementType: "all",
            stylers: [
              { visibility: "simplified" },
              { saturation: -60 },
              { lightness: 60 },
            ],
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [
              { visibility: "off" },
              { saturation: -100 },
              { lightness: 60 },
            ],
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [
              { visibility: "on" },
              { saturation: -100 },
              { lightness: 40 },
            ],
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [
              { visibility: "off" },
              { saturation: -100 },
              { lightness: 60 },
            ],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [{ visibility: "on" }, { color: "#662d91" }],
          },
        ],
      };
      var mapElement = this.$el.querySelector(".map");
      var map = new google.maps.Map(mapElement, mapOptions);
      var markers = this.markers;
      var infoWindows = [];
      var _this = this;
      eventBus.emit("update-props");

      console.log(markers);

      if (this.single == undefined) {
        app.getJSON(
          window.siteUrl + "property.json?showAll=1",
          function (err, data) {
            if (err !== null) {
            } else {
              for (var i = 0; i < data.data.length; i++) {
                var prop = data.data[i];
                if (prop.latitude != null && prop.longitude != null) {
                  var initVisible = false;

                  if (!_this.sale && prop.propertyType[0].title == "For Let") {
                    initVisible = true;
                  }

                  markers[i] = new google.maps.Marker({
                    position: {
                      lat: parseFloat(prop.latitude),
                      lng: parseFloat(prop.longitude),
                    },
                    map: map,
                    title: prop.title,
                    propertyType: prop.propertyType[0].title,
                    visible: initVisible,
                  });

                  var content =
                    `<div id='map-content' >
                                      <h4>` +
                    prop.title +
                    `</h4>`;

                  if (prop.images.length) {
                    content += '<img src="' + prop.images[0].tiny + '">';
                  }

                  content +=
                    "<p>Beds: " +
                    prop.bedrooms +
                    " Bath: " +
                    prop.bathrooms +
                    " Recep: " +
                    prop.receptions +
                    " Price: £" +
                    prop.price +
                    '</p><div class="uk-text-right"><a href="' +
                    prop.url +
                    '" class="uk-button uk-button-text">View Property</a></div></div>';
                  infoWindows[i] = new google.maps.InfoWindow({
                    content: content,
                  });

                  markers[i].markerIndex = i;
                  markers[i].addListener(
                    "click",
                    (function (index) {
                      return function () {
                        clickMarkerEvent(index);
                      };
                    })(i)
                  );
                  function clickMarkerEvent(index) {
                    infoWindows[index].open(map, markers[index]);
                  }
                }
              }
              _this.markersLoaded = true;
            }
          }
        );
      } else {
        markers[0] = new google.maps.Marker({
          position: {
            lat: parseFloat(_this.lat),
            lng: parseFloat(_this.long),
          },
          map: map,
          title: _this.title,
        });
        _this.markersLoaded = true;
      }
    },
  },
  mounted: function () {
    eventBus.on("page:before", () => {
      this.$destroy();
    });
    var _this = this;

    if (document.getElementById("maps-script") == null) {
      loadScript(
        "//maps.googleapis.com/maps/api/js?key=AIzaSyBupzYTUTvzZLaNIQKY5g20gFClGqFCKK0&extension=.js",
        function () {
          _this.initMap();
        },
        "maps-script"
      );
    } else {
      _this.initMap();
    }

    this.sale = false;
  },
};
