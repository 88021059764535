import eventBus from "./utils/eventBus";
import getQueryString from "./utils/getQueryString";
import propCard from "./utils/prop-card";

// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);

const template = `
<section id='listing' class="bg-grey py-16 text-purple">
	<div class="wrapper">
		<div v-if="!openViewing" class="flex flex-col md:flex-row items-center justify-center mb-10">
			<div class="flex-1">
				<select v-model="orderSelect" class="order-select mb-5">
					<option value="price asc">Price Ascending</option>
					<option value="price desc">Price Descending</option>
					<option value="postDate asc">Date Ascending</option>
					<option value="postDate desc" selected="selected">Date Descending</option>
				</select>
			</div>

			<div class="flex-1 flex justify-center">
				<p >
					<strong>Search results:</strong>
					@{total} properties
				</p>
			</div>

			<div class="flex-1 hidden md:block"></div>
		</div>

        <spinner v-if="!allLoaded"></spinner>

        <div v-else-if="propsData" class="flex flex-wrap -ml-10" v-fadein>
          <div v-for="(value, index) in propsData" v-cloak="v-cloak" :key="value.id" class="w-full sm:w-1/2 lg:w-1/3 mb-20 pl-10 prop-card" >
            ${propCard}
          </div>
        </div>

        <div v-if="allLoaded && propsMeta?.pagination?.current_page < propsMeta?.pagination?.total_pages" class="flex justify-center">
            <spinner v-if="loadingProps"></spinner>
            <button v-else @click='nextPage()' class='py-9 px-20 appearance-none border border-purple rounded-md text-purple transition-all hover:bg-purple hover:text-white' >
                Load more
            </button>
        </div>

		
	</div>
</section>
`;

export default {
  name: "prop-listing",
  props: ["selectedProp", "openViewing"],
  data() {
    return {
      total: 0,
      allLoaded: false,
      propsData: [],
      propsMeta: undefined,
      loadingProps: true,
      orderSelect: "postDate desc",
      lastQueryObj: {},
    };
  },
  methods: {
    animate() {
      console.log(this.$refs.propCard);
    },
    getProps(queryObj) {
      queryObj.order = this.orderSelect;
      this.lastQueryObj = queryObj;
      this.loadingProps = true;
      const queryString = getQueryString(queryObj);

      fetch(window.siteUrl + "property.json?" + queryString)
        .then((r) => r.json())
        .then((data) => {
          if (queryObj?.page === 1 || queryObj?.page === undefined) {
            this.propsData = data.data;
          } else {
            this.propsData = [...this.propsData, ...data.data];
          }

          this.propsMeta = data.meta;
          this.total = data.meta.pagination.total;
          this.loadingProps = false;
          this.allLoaded = true;
          this.animate();
          this.$nextTick(() => {
            eventBus.emit("update-props");
          });
        });
    },
    money: function (val) {
      if (isNaN(val)) return "£ - ";
      return val.toLocaleString("en-US", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 0,
      });
    },
    nextPage() {
      let queryObj = this.lastQueryObj;
      queryObj.page = this.propsMeta.pagination.current_page + 1;
      this.getProps(queryObj);
    },
  },
  watch: {
    orderSelect() {
      this.allLoaded = false;
      delete this.lastQueryObj.page;
      this.getProps(this.lastQueryObj);
    },
  },
  mounted() {
    eventBus.on("page:before", () => {
      this.$destroy();
    });
    eventBus.on("get-props", (queryObj) => {
      console.log("get-props", queryObj);
      this.allLoaded = false;
      console.log(queryObj);
      this.getProps(queryObj);
    });
    if (this.selectedProp.length === 0) {
      this.getProps({});
    }
    if(this.openViewing == 'true') {
      this.getProps({
        openViewing: 1
      });
    }
  },
  template,
};
