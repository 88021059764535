export default function getQueryString(params) {
  return Object.keys(params)
    .map((k) => {
      if (Array.isArray(params[k])) {
        return params[k]
          .map((val) => `${encodeURIComponent(k)}[]=${encodeURIComponent(val)}`)
          .join("&");
      }
      if (params[k] == undefined) {
        return;
      }
      return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`;
    })
    .join("&")
    .replace(/(&&)/gm, "&");
}
