export default `
<div class=" bg-cover bg-center w-full rounded-t-xl rounded-l-xl relative overflow-hidden" style="background-image: url(${window.siteUrl}build/img/placehold.png);">
	<div style='padding-bottom: 66.5903890160183%'></div>
	<!--<img class='absolute left-0 w-full h-full top-0 object-cover' v-if="value.images.length" v-lazy="{ loading: value.images[0].placeholder, src: value.images[0].url }">-->

    <span v-if="value.viewingTime && value.openViewing" class="bg-black bg-opacity-30 text-xs p-1 absolute text-white left-0 top-0">Viewing @{value.viewingTime}</span>

    <card-slider :url="value.url" :images="value.images"></card-slider>
	
</div>
<div class="flex justify-between py-7 px-4 ">
	<div class=' w-1/2'>
		<h5 class='font-bold mb-0 text-purple text-[17px] elip' v-if="value.parish.length">@{value.parish[0].title}</h5>
		<h5 class='font-bold mb-0 text-purple text-[17px] elip' v-else>@{value.title}</h5>
	</div>
	<div>
		<h5 class='font-bold mb-0 text-purple text-[17px]'>
			<span v-if="value.webStatus == 0 || value.webStatus == 4">For Sale</span>
			<span v-if="value.webStatus == 1">Under Offer</span>
			<span v-if="value.webStatus == 2">Sold</span>
			<span v-if="value.webStatus == 3">Sold (SSTC)</span>
			<span v-if="value.webStatus == 100">To Let</span>
			<span v-if="value.webStatus == 101">Let</span>
			<span v-if="value.webStatus == 102">Under Offer</span>
			<span v-if="value.webStatus == 103">Reserved</span>
			<span v-if="value.webStatus == 104">Let Agreed</span>
		</h5>
	</div>
</div>
<div class="border-t border-b border-border flex justify-start items-center py-[14px] px-4 text-[17px] ">
    
    <div class='flex items-center '>
        @{value.bedrooms}
        <svg class=' h-5 xl:h-6 mx-2 sm:mx-1 xl:mx-2 w-auto ' xmlns="http://www.w3.org/2000/svg" width="34.904" height="24.979" viewBox="0 0 34.904 24.979">
            <g id="Group_2180" data-name="Group 2180" transform="translate(-410.5 -1609.5)">
                <path id="Path_2728" data-name="Path 2728" d="M121.372,264.065v-9.49a1.244,1.244,0,0,1,1.244-1.244h24.438a1.244,1.244,0,0,1,1.244,1.244v9.473" transform="translate(292.985 1356.669)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_2729" data-name="Path 2729" d="M112.687,290.64a1.35,1.35,0,0,0,1.35,1.35h31.2a1.351,1.351,0,0,0,1.35-1.35l-.07-6.088a3.5,3.5,0,0,0-3.495-3.495H116.183a3.5,3.5,0,0,0-3.495,3.495Z" transform="translate(298.313 1339.66)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <g id="Group_1508" data-name="Group 1508" transform="translate(413.342 1631.651)">
                <rect id="Rectangle_198" data-name="Rectangle 198" width="6.066" height="2.328" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <rect id="Rectangle_199" data-name="Rectangle 199" width="6.066" height="2.328" transform="translate(23.154)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                </g>
                <g id="Group_1509" data-name="Group 1509" transform="translate(418.485 1617.846)">
                <path id="Path_2730" data-name="Path 2730" d="M140.212,276.5v-1.559a1.312,1.312,0,0,0-1.312-1.312h-5.534a1.312,1.312,0,0,0-1.312,1.312V276.5Z" transform="translate(-132.053 -273.629)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_2731" data-name="Path 2731" d="M167.4,276.5v-1.559a1.312,1.312,0,0,0-1.312-1.312h-5.535a1.312,1.312,0,0,0-1.312,1.312V276.5Z" transform="translate(-148.735 -273.629)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                </g>
                <line id="Line_45" data-name="Line 45" x2="33.882" transform="translate(411 1628.546)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            </g>
        </svg>
    </div>
    
    <div class='flex items-center '>
        @{value.receptions}
        <svg class=' h-5 xl:h-6 mx-2 sm:mx-1 xl:mx-2 w-auto '  xmlns="http://www.w3.org/2000/svg" width="35.281" height="24.987" viewBox="0 0 35.281 24.987">
            <g id="Group_2180" data-name="Group 2180" transform="translate(-342.5 -1609.5)">
                <path id="Path_2732" data-name="Path 2732" d="M86.2,301.011l1.157,4.1a1.093,1.093,0,0,1-.755,1.349l-.153.043a1.093,1.093,0,0,1-1.349-.755l-.669-2.372v-1.961" transform="translate(287.419 1327.405)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_2733" data-name="Path 2733" d="M65.733,286.74a2.611,2.611,0,0,0-2.611-2.61H56.74a2.611,2.611,0,0,0-2.611,2.61v1.027h11.6Z" transform="translate(306.011 1337.761)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_2734" data-name="Path 2734" d="M15.32,277.519v-7.234a2.768,2.768,0,1,0-3.445,2.685s.028,3.488.028,4.837a3.062,3.062,0,0,0,3.062,3.062H38.884a3.062,3.062,0,0,0,3.062-3.062c0-1.348.028-4.837.028-4.837a2.768,2.768,0,1,0-3.445-2.685v7.234" transform="translate(333.216 1347.953)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_2735" data-name="Path 2735" d="M17.346,301.011l-1.157,4.1a1.093,1.093,0,0,0,.755,1.349l.152.043a1.093,1.093,0,0,0,1.349-.755l.669-2.372v-1.961" transform="translate(329.312 1327.405)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_2736" data-name="Path 2736" d="M18.473,258.9A5.532,5.532,0,0,1,24,253.366H40.5a5.532,5.532,0,0,1,5.532,5.532" transform="translate(327.885 1356.634)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_2737" data-name="Path 2737" d="M24.107,286.74a2.611,2.611,0,0,1,2.611-2.61H33.1a2.61,2.61,0,0,1,2.61,2.61v1.027h-11.6Z" transform="translate(324.429 1337.761)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <line id="Line_46" data-name="Line 46" x2="23.428" transform="translate(348.426 1630.783)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <circle id="Ellipse_22" data-name="Ellipse 22" cx="0.675" cy="0.675" r="0.675" transform="translate(351.06 1616.725)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <circle id="Ellipse_23" data-name="Ellipse 23" cx="0.675" cy="0.675" r="0.675" transform="translate(356.497 1616.725)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <circle id="Ellipse_24" data-name="Ellipse 24" cx="0.675" cy="0.675" r="0.675" transform="translate(361.933 1616.725)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <circle id="Ellipse_25" data-name="Ellipse 25" cx="0.675" cy="0.675" r="0.675" transform="translate(353.778 1613.576)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <circle id="Ellipse_26" data-name="Ellipse 26" cx="0.675" cy="0.675" r="0.675" transform="translate(359.215 1613.576)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <circle id="Ellipse_27" data-name="Ellipse 27" cx="0.675" cy="0.675" r="0.675" transform="translate(364.651 1613.576)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <circle id="Ellipse_28" data-name="Ellipse 28" cx="0.675" cy="0.675" r="0.675" transform="translate(367.369 1616.725)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            </g>
        </svg>
    </div>
    
    <div class='flex items-center '>
        @{value.bathrooms}
        <svg class=' h-5 xl:h-6 mx-2 sm:mx-1 xl:mx-2 w-auto '  xmlns="http://www.w3.org/2000/svg" width="25.073" height="24.952" viewBox="0 0 25.073 24.952">
            <g id="Group_2181" data-name="Group 2181" transform="translate(-478.5 -1609.5)">
                <path id="Path_2722" data-name="Path 2722" d="M219.837,263.515v-7.636a2.479,2.479,0,0,1,2.338-2.512,2.412,2.412,0,0,1,2.486,2.411v1.886a.3.3,0,0,1-.3.3h-.907a.3.3,0,0,1-.3-.3V255.83a.95.95,0,0,0-.82-.962.914.914,0,0,0-1.008.909v7.737" transform="translate(260.769 1356.634)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_2723" data-name="Path 2723" d="M221.179,281.619V280.03a.407.407,0,0,0-.407-.407h-1.5a.407.407,0,0,0-.407.407v1.589" transform="translate(261.367 1340.526)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_2724" data-name="Path 2724" d="M216.5,284.782h6.277v1.639H216.5a.819.819,0,1,1,0-1.639Z" transform="translate(263.318 1337.361)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_2725" data-name="Path 2725" d="M260.558,285.6a.819.819,0,0,1-.819.82H249.592v-1.639h10.146A.82.82,0,0,1,260.558,285.6Z" transform="translate(242.515 1337.361)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_2726" data-name="Path 2726" d="M240.052,283.788v9.13a.556.556,0,0,1-.557.556h-4.9a.554.554,0,0,1-.557-.556v-9.13a.557.557,0,0,1,.557-.557h4.9A.559.559,0,0,1,240.052,283.788Z" transform="translate(252.055 1338.312)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <line id="Line_40" data-name="Line 40" x2="6.012" transform="translate(486.095 1628.64)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <line id="Line_41" data-name="Line 41" x2="6.012" transform="translate(486.095 1630.085)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <path id="Path_2727" data-name="Path 2727" d="M240.21,289.022v2.171a8,8,0,0,1-8,8h-6.8a8,8,0,0,1-8-8v-2.171" transform="translate(262.254 1334.76)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <line id="Line_42" data-name="Line 42" y2="5.056" transform="translate(480.23 1628.896)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <line id="Line_43" data-name="Line 43" y2="1.785" transform="translate(482.633 1632.167)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
                <line id="Line_44" data-name="Line 44" x2="22.602" transform="translate(479.283 1633.952)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            </g>
        </svg>
    </div>
    
    <div class='flex items-center '>
        @{value.parking != null ? value.parking : 0}
        <svg class=' h-5 xl:h-6 mx-2 sm:mx-1 xl:mx-2 w-auto ' xmlns="http://www.w3.org/2000/svg" width="33.461" height="24.064" viewBox="0 0 33.461 24.064">
        <g id="Group_1513" data-name="Group 1513" transform="translate(-536.5 -1610.5)">
            <path id="Path_2738" data-name="Path 2738" d="M332.2,300.392v1.446a.889.889,0,0,1-.881.881h-6.03a.89.89,0,0,1-.754-.417.866.866,0,0,1-.131-.464v-1.446a.883.883,0,0,1,.2-.564.9.9,0,0,1,.684-.321h6.03a.889.889,0,0,1,.68.321A.905.905,0,0,1,332.2,300.392Z" transform="translate(224.832 1328.883)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Path_2739" data-name="Path 2739" d="M306.376,300.9v1.446a.866.866,0,0,0,.131.464H295.758a.742.742,0,0,1-.742-.738v-1a.739.739,0,0,1,.742-.738h10.819A.883.883,0,0,0,306.376,300.9Z" transform="translate(242.862 1328.374)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Path_2740" data-name="Path 2740" d="M355.8,301.075v1a.741.741,0,0,1-.742.738H344.122a.869.869,0,0,0,.135-.464V300.9a.905.905,0,0,0-.2-.564h11A.739.739,0,0,1,355.8,301.075Z" transform="translate(212.777 1328.374)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            <g id="Group_1512" data-name="Group 1512" transform="translate(538.843 1631.186)">
            <path id="Path_2741" data-name="Path 2741" d="M303.786,306.74v1.99a.888.888,0,0,1-.888.888h-4.5a.888.888,0,0,1-.888-.888v-1.99" transform="translate(-297.512 -306.74)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Path_2742" data-name="Path 2742" d="M361.512,306.74v1.99a.888.888,0,0,1-.888.888h-4.5a.888.888,0,0,1-.888-.888v-1.99" transform="translate(-332.926 -306.74)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            </g>
            <circle id="Ellipse_29" data-name="Ellipse 29" cx="1.946" cy="1.946" r="1.946" transform="translate(540.684 1622.287)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Path_2743" data-name="Path 2743" d="M296,272.269h-2.36a.891.891,0,0,0-.891.891v1.85a.891.891,0,0,0,.891.891h1.333" transform="translate(244.255 1345.593)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Path_2744" data-name="Path 2744" d="M327.052,264.029s.551-9.514-4.647-9.514h-16.79c-5.2,0-4.647,9.514-4.647,9.514" transform="translate(239.221 1356.485)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Path_2745" data-name="Path 2745" d="M328,266s.237-7.01-3.347-7.01H308.412c-3.584,0-3.348,7.01-3.348,7.01" transform="translate(236.7 1353.741)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Path_2746" data-name="Path 2746" d="M325.992,285.892v-4.544a4.646,4.646,0,0,0-4.646-4.646H300.855a4.646,4.646,0,0,0-4.646,4.646v4.544" transform="translate(242.13 1342.873)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            <circle id="Ellipse_30" data-name="Ellipse 30" cx="1.946" cy="1.946" r="1.946" transform="translate(561.885 1622.287)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Path_2747" data-name="Path 2747" d="M330.2,276.7s-1.635,2.592-2.05,3.267-.441,1.328-3.477,1.328H320.29c-3.036,0-3.062-.653-3.477-1.328s-2.05-3.267-2.05-3.267" transform="translate(230.747 1342.873)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Path_2748" data-name="Path 2748" d="M368.318,272.269h2.36a.891.891,0,0,1,.891.891v1.85a.891.891,0,0,1-.891.891h-1.333" transform="translate(197.893 1345.593)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Path_2749" data-name="Path 2749" d="M344.077,272.968a2.353,2.353,0,1,1,4.706,0" transform="translate(212.764 1346.608)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Path_2750" data-name="Path 2750" d="M339.625,270.237a4.074,4.074,0,1,1,8.148,0" transform="translate(215.495 1349.339)" fill="none" stroke="#48338a" stroke-miterlimit="10" stroke-width="1"/>
        </g>
        </svg>

    </div>
	
	<span class='font-bold ml-auto elip'>@{ money(value.price) }</span>
</div>
`;
