import eventBus from "./utils/eventBus";

const template = `<section class="purple-bg mt-16">
<div class="wrapper py-16 text-center ">
    <h4 class='text-white font-bold'>Let's do the numbers</h4>

    <div class="max-w-5xl mx-auto border-white border-t flex flex-col md:flex-row justify-between">
        
        <div class="w-full md:w-[390px] lg:w-[430px] mt-16 flex flex-col">

            <h4 class='text-white font-bold mb-0'>Mortgage calculator</h4>
            <p class='text-white mt-2'>Fill out all the fields:</p>

            <label class='flex flex-col text-white text-left mb-5'>
                Amount to borrow: (£)
                <input type="number" v-model="borrow" class=' text-purple rounded-xl px-7 py-5 mt-2 '>
            </label>

            <div class="flex mb-5">

                <div class="flex-1 mr-5 ">

                    <label class='flex flex-col text-white text-left  '>
                        No. of years:
                        <input type="number" v-model="years" class=' text-purple rounded-xl px-7 py-5 mt-2 w-full '>
                    </label>
                    <div class='text-white mt-5 '>
                        Monthly payment:
                        <h4 class="text-white font-bold elip mb-0 ">@{money(monthly)}</h4>
                    </div>
                </div>
                <div class="flex-1 ">
                    <label class='flex flex-col text-white text-left  '>
                        Interest rate: (%)
                        <input type="number" min="0" max="100" v-model="rate" class=' text-purple rounded-xl px-7 py-5 mt-2 w-full '>
                    </label>
                    <div class='text-white mt-5 '>
                        Total interest:
                        <h4 class="text-white font-bold elip mb-0 ">
                            @{ money((monthly * (years * 12)) - borrow) }
                        </h4>
                    </div>
                </div>
            </div>
            <div class="py-8 border-white border-t border-b text-white mt-auto">
                Total paid:
                <h4 class="text-white font-bold mb-0 elip">@{ money((monthly*(years * 12))) }</h4>
            </div>
        </div>
        <div class="w-full md:w-[390px] lg:w-[430px] mt-16 flex flex-col">
            <h4 class='text-white font-bold mb-0'>Stamp duty calculator</h4>
            <p class='text-white mt-2'>Fill out all the fields:</p>
            <label class='flex flex-col text-white text-left mb-5'>
                Value of estate: (£)
                <input type="number" min="0" v-model="price" class=' text-purple rounded-xl px-7 py-5 mt-2 '>
            </label>

            <div class="py-8 border-white border-t border-b text-white mt-auto">
                Stamp duty:
                <h4 class="text-white font-bold mb-0">@{ stamp(price) }</h4>
            </div>
        </div>
    </div>
</div>
</section>`;

export default {
  props: ["init-price"],
  data() {
    return {
      borrow: this.initPrice,
      price: this.initPrice,
      years: 10,
      rate: 5,
    };
  },
  methods: {
    money: function (val) {
      if (isNaN(val)) return "£ - ";

      return val.toLocaleString("en-US", {
        style: "currency",
        currency: "GBP",
      });
    },
    stamp(input) {
      const formatStamp = (input) => this.money(Math.floor(input));
      var stamp = "NO FEE";
      input = +input;

      if (input >= 10001 && input <= 100000) {
        stamp = (input / 100) * 0.5;
        stamp = formatStamp(stamp);
      }
      if (input >= 100000) {
        stamp = 500 + 0.75 * ((input - 99901) / 100);
        stamp = formatStamp(stamp);
      }

      return stamp;
    },
  },
  computed: {
    monthly: function () {
      var e = this.borrow,
        t = this.rate / 100 / 12,
        i = 12 * this.years,
        s = e * ((t * Math.pow(1 + t, i)) / (Math.pow(1 + t, i) - 1));
      return s;
    },
  },
  mounted() {
    eventBus.on("page:before", () => {
      this.$destroy();
    });
  },
  name: "mortgage-calc",
  template,
};
